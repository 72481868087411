import {
    GET_MY_ARTICLES_COUNT_START,
    GET_MY_ARTICLES_COUNT_SUCCESS,
    GET_MY_ARTICLES_COUNT_FAILED,
} from '../actions/actionTypes';

const initialState = {
    count: {},
    fetching: false,
    errorMessage: false,
};

const myArticlesReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_MY_ARTICLES_COUNT_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case GET_MY_ARTICLES_COUNT_SUCCESS:
            return {
                ...state,
                count: payload,
                fetching: false,
            };
        case GET_MY_ARTICLES_COUNT_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: payload,
            };
        default:
            return state;
    }
};

export default myArticlesReducer;
